import React, { useEffect, useState } from "react";
import './PDFViewer.css'
import { Box, MenuItem, Select, Tooltip, Typography } from "@mui/material";
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import PDFData from "../../component/PDFData";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { recipient_action } from "../../store/recipientDetails/recipientAction";
import { toast } from "react-toastify";
import { Navigation } from "../../utils/handleNavigation";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";
import { toggleLoader } from "../../store/loader/loaderReducer";
import { validateEmail } from "../../utils/validation";
import { LimitModal } from "../../component/LimitModal";
import Skeleton from '@mui/material/Skeleton';
import ApprovalIcon from '@mui/icons-material/Approval';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ApprovarModal from "../../component/ApprovarModal";
import EditIcon from '@mui/icons-material/Edit';
import { signId_action } from "../../store/signIDDetails/signId_action";

const basicFields = ['Name', 'Company', 'Signature', 'Date', 'Radio', 'Initial', 'Text', 'Checkbox', 'Number', 'Title', 'Email', 'Dropdown'];
const initialCount = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0, 12: 0 };
const fontColors = ["Black", "White", "Navy blue", "Bright blue", "Purple", "Dark red", "Bright red", "Gold", "Green", "Dark green"];
const hexCode = ["#000000", "#fff", "#000080", "#00BFFF", "#800080", "#8B0000", "#FF0000", "#FFD700", "#008000", "#006400"];

const PDFViewer = () => {
    const [date, setDate] = useState('');
    const [signType, setSignType] = useState('Send to others');
    const [selectedPage, setSelectedPage] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [controls, setControls] = useState({});
    const [recipient, setRecipient] = useState([]);
    const [docName, setDocName] = useState('');
    const [fileLoader, setFileLoader] = useState(false);
    const [selectRecipients, setSelectRecipients] = useState(0);
    const [selectColor, setSelectColor] = useState(null);
    const [pageInfo, setPageInfo] = useState([]);
    const [signOrder, setSignOrder] = useState(false);
    const [passCodeDetails, setPassCodeDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [limit, setLimit] = useState(false);
    const [user_id, setUser_id] = useState([]);
    const [approvals, setApprovals] = useState(false);
    const [open, setOpen] = useState(false);
    const [reasonType, setReasonType] = useState('');

    const dispatch = useDispatch();
    const navPath = Navigation();
    const navigate = useNavigate();

    const document_details = useSelector((state) => state?.document_details?.value);
    const signId_details = useSelector((state) => state?.signId_details?.value);
    const signature_id = useSelector((state) => state.signature_id.value);
    const crm_param = useSelector((state) => state.crm_param.value);
    const pulseId = useSelector((state) => state.pulseId.value);
    const [searchParams] = useSearchParams();
    const location = useLocation();

    const pathName = location?.pathname?.split('/')?.find(v => v === 'edit-template');
    const draft = location?.pathname?.split('/').find(s => s === 'document');
    const newTemplate = location?.pathname?.split('/')?.find(s => s === 'edit-as-new-template' || s === 'send-sign' || s === 'sign-myself');
    const draftType = window?.location?.href?.split('&')?.find(s => s === 'action=draft' || s === 'action=approval' || s === 'action=approved' || s === 'action=edit');
    const sdk_document_id = location?.pathname?.split('/')[3];
    const sdk_org_id = window?.location?.href?.split('#')[1]?.split('/')[1]?.split('c')[1];
    const navigationEntries = window.performance.getEntriesByType('navigation');
    const sign_id = navigationEntries[0]?.name?.split('?')[1]?.split('&')[0]?.split('=')[1];

    const show_integeration = useSelector((state) => state.welcomeImgDetail.value);
    const admin = show_integeration?.user_information?.userRole.includes('Super Admin', 'Admin');
    const closeModal = admin || show_integeration?.user_permission[3]?.create === 1 || show_integeration?.user_permission[4]?.create === 1 || show_integeration?.user_permission[2]?.create === 1;
    const disable_send_btn = admin || show_integeration?.user_permission[3]?.create === 1 || show_integeration?.user_permission[4]?.create === 1;
    const approver_user = signId_details?.approvers_info?.find((s) => s?.approver_User_Id === show_integeration?.user_information?.Id);

    useEffect(() => {
        let doc_name = document_details?.template_information ? document_details?.template_information?.Template_Name : document_details?.item?.Document_Name;
        let modified = document_details?.template_information ? moment(new Date(document_details?.template_information?.Modified_Time)).format('DD/MM/YYYY') : moment(new Date(document_details?.item?.Modified_Time)).format('DD/MM/YYYY');
        if (crm_param) {
            doc_name = document_details?.item?.Document_Name;
            modified = moment(new Date(document_details?.item?.Modified_Time)).format('DD/MM/YYYY');
        };
        setDocName(doc_name);
        setDate(modified);
    }, [document_details]);



    const go_to_mydocument = () => {
        if (draft === 'document') {
            navPath('/my-documents');
        } else if ((pathName === 'edit-template' || newTemplate) && !draftType) {
            navPath('/templates');
        } else if (draftType) {
            if (document_details?.template_information?.Type === 1) {
                navPath('/sign-myself');
            } else {
                navPath('/send-sign');
            }
        } else {
            navPath('/my-documents');
        }
    };

    const save_template = (key) => {
        const data = document_details?.document_information ? document_details?.document_information : document_details?.item;

        let field_properties = {};
        let field_positions = {};
        let format_object = {};

        const counts = {};
        let arr = [];
        let error = [];

        Object.keys(controls)?.map((val) => {
            let property = {};
            let options = {};
            controls[val].map((item) => {
                if (item?.sign_type === signType) {
                    if (item?.field_key === 'Email' && item?.value && item?.sign_type === 'Sign myself') {
                        let val = validateEmail(item?.value);
                        if (!val) {
                            toast.error(item.value + ' email is invalid')
                            error.push('email');
                        }
                    }
                    arr.push(item.field_key);
                    let field_name = item.field_value.replaceAll(' ', '_');
                    if (item?.field_key === 'Radio' || item?.field_key === 'Checkbox') {
                        item.options.map((list, idx) => {
                            let lnth = Object.keys(options).length === 0 ? 1 : Object.keys(options).length + 1;
                            let option_name = `${item.field_key.split(' ')[0] + '_options_' + lnth}`.toLowerCase();
                            let option_value = `${item.field_key.split(' ')[0] + '_value'}`.toLowerCase();
                            options = {
                                ...options, [option_name]: {
                                    field_value: item.field_value, key: item.key, group_id: item?.group_id, value: item?.value, is_required: item?.is_required,
                                    [option_value]: { id: list.id, value: list.value, is_selected: list.is_selected, top: list.top, left: list.left, option_name: option_name }, type: item.type
                                }
                            };
                            field_positions = {
                                ...field_positions, [option_name]: {
                                    top: list.top, left: list.left, background: item.background, width: item.width,
                                    key: item.key, height: item.height
                                }
                            }
                            property = { ...options, ...property };
                        })
                    } else if (item?.field_key === 'Dropdown') {
                        property = {
                            ...property,
                            [field_name]: {
                                width: item?.width, field_value: item?.field_value, value: item?.value, dropdown_value: item?.options, default_value: '', type: item.type, key: item?.key, height: item?.height, is_required: item?.is_required
                            }
                        };

                        field_positions = {
                            ...field_positions, [field_name]: {
                                top: item.top - 9, left: item.left + 2, background: item.background, width: item.width,
                                key: item.key, height: item.height
                            }
                        }

                        format_object = {
                            ...format_object, [field_name]: {
                                bold: item?.fontWeight === 'bold' ? true : false,
                                italic: item?.fontStyle === 'italic' ? true : false,
                                alignment: "left",
                                font_size: item?.fontSize,
                                color: { name: fontColors[hexCode?.indexOf(item?.color)], value: item?.color }
                            }
                        }
                    } else if (item?.field_key === 'Date') {
                        property = {
                            ...property,
                            [field_name]: {
                                is_required: item?.is_required, width: item?.width, field_value: item?.field_value, date_format: item?.date_format,
                                is_read_only: item?.is_read_only, default_value: item?.value, type: item.type, key: item?.key, height: item?.height
                            }
                        };

                        field_positions = {
                            ...field_positions, [field_name]: {
                                top: item.top - 9, left: item.left + 2, background: item.background, width: item.width,
                                key: item.key, height: item.height
                            }
                        }

                        format_object = {
                            ...format_object, [field_name]: {
                                font_family: item?.fontFamily,
                                bold: item?.fontWeight === 'bold' ? true : false,
                                italic: item?.fontStyle === 'italic' ? true : false,
                                alignment: "left",
                                font_size: item?.fontSize,
                                color: { name: fontColors[hexCode?.indexOf(item?.color)], value: item?.color }
                            }
                        }
                    } else if (item?.field_key === 'Signature') {
                        property = {
                            ...property,
                            [field_name]: {
                                is_required: item?.is_required, width: item?.width, field_value: item?.type !== 12 ? item?.field_value : item?.initial_value,
                                is_read_only: item?.is_read_only, default_value: item?.value, type: item.type, key: item?.key, height: item.height, board_details: item?.board_details ? item?.board_details : null
                            }
                        };

                        field_positions = {
                            ...field_positions, [field_name]: {
                                top: item.top, left: item.left, background: item.background, width: item.width,
                                key: item.key, height: item.height
                            }
                        }
                    } else {
                        property = {
                            ...property,
                            [field_name]: {
                                is_required: item?.is_required, width: item?.width, field_value: item?.type !== 12 ? item?.field_value : item?.initial_value,
                                is_read_only: item?.is_read_only, default_value: item?.value, type: item.type, key: item?.key, height: item?.height, board_details: item?.board_details ? item?.board_details : null
                            }
                        };

                        field_positions = {
                            ...field_positions, [field_name]: {
                                top: item.top - 9, left: item.left + 2, background: item.background, width: item.width,
                                key: item.key, height: item.height
                            }
                        }

                        format_object = {
                            ...format_object, [field_name]: {
                                font_family: item?.fontFamily,
                                bold: item?.fontWeight === 'bold' ? true : false,
                                italic: item?.fontStyle === 'italic' ? true : false,
                                alignment: "left",
                                font_size: item?.fontSize,
                                color: { name: fontColors[hexCode?.indexOf(item?.color)], value: item?.color }
                            }
                        }
                    }
                }
            })

            field_properties = { ...field_properties, [val]: { ...property } };
            field_positions = { ...field_positions };
            format_object = { ...format_object };
        });

        let px_ratio = 1;
        let screenWidthInches = window.screen.width / px_ratio / 96;
        let screenHeightInches = window.screen.height / px_ratio / 96;
        let diagonalSizeInches = Math.sqrt(screenWidthInches * screenWidthInches + screenHeightInches * screenHeightInches);
        let DPI = Math.sqrt(window.screen.width * window.screen.width + window.screen.height * window.screen.height) / diagonalSizeInches;

        arr.forEach((x) => { counts[basicFields.indexOf(x) + 1] = (counts[basicFields.indexOf(x) + 1] || 0) + 1; });
        Object.keys(counts).map(item => initialCount[item] = counts[item]);

        let type = signType === 'Sign myself' ? 1 : 2;

        let set_order_list = signOrder ? [...recipient]?.sort((a, b) => a.order - b.order) : [...recipient]?.map((v) => { let del = ({ ...v }); delete del.order; return del; });

        let field_value_obj = {
            field_properties: field_properties,
            radio_groups_count: initialCount[5],
            checkbox_groups_count: initialCount[8],
            dropdown_options_count: initialCount[12],
            field_count: initialCount,
            page_properties: { pages: pageInfo, client_properties: { screen_dpi: DPI } },
            field_positions_object: field_positions,
            format_object: format_object,
            recipient_info: type === 2 ? set_order_list : [],
            is_order: signOrder,
        };

        if (type === 2 && set_order_list?.length && passCodeDetails) {
            if (Object?.keys(passCodeDetails)?.length) field_value_obj['hidden_proerties'] = { password_keys: passCodeDetails }
        }

        if (user_id?.length && type === 2) field_value_obj['approvers'] = user_id;

        let field = JSON.stringify(field_value_obj);

        // const save = key === 'template' || (pathName && key !== 'proceed') ? 1 : 0;
        const is_draft = pathName === 'edit-template' ? 0 : 1;
        const templateId = document_details?.template_information?.Id;

        let search_param = searchParams.get('id') ? key === 'template' ? true : false : true;
        let optionList = signType === 'Sign myself' && arr.indexOf('Signature') !== -1 ? {
            org_id: sdk_org_id ? sdk_org_id : data?.Org_Id,
            document_id: data?.Id,
            template_name: docName,
            properties: field,
            type: type,
            signature: signature_id,
        } :
            {
                org_id: crm_param && sdk_org_id ? sdk_org_id : data?.Org_Id,
                document_id: crm_param ? sdk_document_id : data?.Id,
                template_name: docName,
                properties: field,
                type: type,
            };

        let optionList2 = signType === 'Sign myself' && arr.indexOf('Signature') !== -1 ? {
            // isdraft: is_draft,
            org_id: sdk_org_id ? sdk_org_id : data?.Org_Id,
            template_id: templateId,
            template_name: docName,
            properties: field,
            type: type,
            signature: signature_id,
        } :
            {
                // isdraft: is_draft,
                org_id: sdk_org_id ? sdk_org_id : data?.Org_Id,
                template_id: templateId,
                template_name: docName,
                properties: field,
                type: type,
            };

        let options = draft === 'document' || (newTemplate && search_param) ? optionList : optionList2;

        if (crm_param) {
            const state = {
                crmparam: decodeURIComponent(crm_param?.split('&pulseId')[0]),
            };

            options.state = state;
        };

        let saveTemplate = signType === 'Sign myself' ? {
            // isdraft: is_draft,
            org_id: sdk_org_id ? sdk_org_id : data?.Org_Id,
            template_id: templateId,
            template_name: docName,
            properties: field,
            type: type,
            signature: signature_id,
        } :
            {
                // isdraft: is_draft,
                org_id: crm_param || signId_details?.crm_param === 'crm_draft' ? sdk_org_id ?? data?.Org_Id : data?.Org_Id,
                template_id: crm_param || signId_details?.crm_param === 'crm_draft' ? sdk_document_id : templateId,
                template_name: docName,
                properties: field,
                type: type,
            };

        if (!error?.length) {
            dispatch(toggleLoader(true));
            setLoading(true);
            if (key !== 'save' && !pathName && search_param) {
                switch (key) {
                    case 'send':
                        axios.post(process.env.REACT_APP_REQUEST_TEMPLATE, options, { withCredentials: process.env.REACT_APP_CREDENTIALS })
                            .then((res) => {
                                let data = res.data;
                                let recipientList = type === 2 ? recipient : [{ email: '', name: '' }];
                                let obj = { data, recipientList };
                                dispatch(toggleLoader(false));
                                if (key === 'template') {
                                    navPath('/templates');
                                } else {
                                    dispatch(recipient_action(obj));
                                    let sign_id_details = { ...signId_details };
                                    sign_id_details['id'] = res.data?.Sign_Id;
                                    dispatch(signId_action(sign_id_details));
                                    navPath(`/template/${data.Id}/add_recipient?sign_type=${type}`);
                                }
                            }).catch((err) => {
                                dispatch(toggleLoader(false));
                                setLoading(false);
                                if (err?.response?.data?.error?.status === 600) {
                                    setLimit(true);
                                } else {
                                    toast.error(err.message);
                                }
                            })
                        break;

                    default:
                        axios.post(process.env.REACT_APP_TEMPLATES, options, { withCredentials: process.env.REACT_APP_CREDENTIALS })
                            .then((res) => {
                                let data = res.data;
                                let recipientList = type === 2 ? recipient : [{ email: '', name: '' }];
                                let obj = { data, recipientList };
                                dispatch(toggleLoader(false));
                                if (key === 'template') {
                                    navPath('/templates');
                                } else {
                                    dispatch(recipient_action(obj));
                                    navPath(`/template/${data.Id}/add_recipient?sign_type=${type}`);
                                }
                            }).catch((err) => {
                                dispatch(toggleLoader(false));
                                setLoading(false);
                                if (err?.response?.data?.error?.status === 600) {
                                    setLimit(true);
                                } else {
                                    toast.error(err.message);
                                }
                            })
                        break;
                }
            } else {
                switch (key) {
                    case 'send':
                        if (arr.indexOf('Signature') === -1) delete saveTemplate['signature'];
                        axios.put(process.env.REACT_APP_REQUEST_TEMPLATE, saveTemplate, { withCredentials: process.env.REACT_APP_CREDENTIALS })
                            .then((res) => {
                                dispatch(toggleLoader(false));
                                setLoading(true);
                                let signId = res?.data?.Sign_Id ? res.data.Sign_Id : signId_details?.id;
                                if (key === 'save' || key === 'template') {
                                    navPath('/templates');
                                } else {
                                    let data = { Id: templateId, Sign_Id: signId_details?.crm_param === 'crm_draft' ? sign_id : signId, Type: type, Properties: res?.data?.Properties };
                                    let recipientList = type === 2 ? recipient : [{ email: '', name: '' }];
                                    let obj = { data, recipientList };
                                    dispatch(recipient_action(obj));
                                    navPath(`/template/${templateId}/add_recipient?sign_type=${type}`);
                                }
                            }).catch((err) => {
                                toast.error(err.message);
                                dispatch(toggleLoader(false));
                                setLoading(false);
                            })
                        break;
                    case 'proceed':
                        axios.post(process.env.REACT_APP_SAVEANDPROCEED, saveTemplate, { withCredentials: process.env.REACT_APP_CREDENTIALS })
                            .then((res) => {
                                dispatch(toggleLoader(false));
                                setLoading(true);
                                let signId = res?.data?.Sign_Id ? res.data.Sign_Id : signId_details?.id;
                                if (key === 'save' || key === 'template') {
                                    navPath('/templates');
                                } else {
                                    let data = { Id: templateId, Sign_Id: signId_details?.crm_param === 'crm_draft' ? sign_id : signId, Type: type, Properties: res?.data?.Properties };
                                    let recipientList = type === 2 ? recipient : [{ email: '', name: '' }];
                                    let obj = { data, recipientList };
                                    dispatch(recipient_action(obj));
                                    navPath(`/template/${templateId}/add_recipient?sign_type=${type}`);
                                }
                            }).catch((err) => {
                                toast.error(err.message);
                                dispatch(toggleLoader(false));
                                setLoading(false);
                            }).finally(() => dispatch(toggleLoader(false)))
                        break;
                    default:
                        axios.put(process.env.REACT_APP_TEMPLATES, saveTemplate, { withCredentials: process.env.REACT_APP_CREDENTIALS })
                            .then((res) => {
                                dispatch(toggleLoader(false));
                                setLoading(true);
                                let signId = res?.data?.Sign_Id ? res.data.Sign_Id : signId_details?.id;
                                if (key === 'save' || key === 'template') {
                                    navPath('/templates');
                                } else {
                                    let data = { Id: templateId, Sign_Id: signId_details?.crm_param === 'crm_draft' ? sign_id : signId, Type: type };
                                    let recipientList = type === 2 ? recipient : [{ email: '', name: '' }];
                                    let obj = { data, recipientList };
                                    dispatch(recipient_action(obj));
                                    navPath(`/template/${templateId}/add_recipient?sign_type=${type}`);
                                }
                            }).catch((err) => {
                                toast.error(err.message);
                                dispatch(toggleLoader(false));
                                setLoading(false);
                            }).finally(() => dispatch(toggleLoader(false)));
                        break;
                }
            }
        }
    };

    const handleRecipient = (item) => {
        let active_type = item?.findIndex(s => s.type === 1);
        setSelectRecipients(active_type);
        setSelectColor(item[active_type]?.background);
        setRecipient(item);
    };

    const filterItemsByType = (data, id) => {
        const matchedItems = [];
        Object.keys(data)?.forEach(key => {
            const filteredItems = data[key]?.filter(item => item?.sign_type === id);
            matchedItems.push(...filteredItems);
        });
        return matchedItems;
    };

    const handleErrorMsg = (key) => {
        let data = Object.keys(controls)?.filter(s => controls[s]?.length);
        let filter_type = filterItemsByType(controls, signType);

        let recipient_type = recipient?.find(s => s.type === 4);
        if (recipient?.length === 0 && signType === 'Send to others' && !newTemplate) {
            setShowModal(true);
        } else if ((data?.length && filter_type?.length) || (recipient_type && signType === 'Send to others')) {
            save_template('send');
            setLoading(true);
        } else {
            if (recipient?.find(s => s.type === 2)) {
                toast.warning('Atleast one signer recipient with field is needed before sending.');
                setShowModal(true);
            } else {
                toast.error('Please add at least one field before sending.');
            }
        }
    };

    const handleSave = (key) => {
        let data = Object?.keys(controls)?.filter(s => controls[s]?.length);
        let recipient_type = recipient?.find(s => s.type === 4);
        let filter_type = filterItemsByType(controls, signType);
        if (data?.length && filter_type?.length) {
            switch (key) {
                case 'save':
                    save_template('save');
                    break;
                case 'template':
                    save_template('template');
                    break;
                default:
                    save_template('proceed');
                    break;
            }
        } else if (recipient?.length && recipient_type && signType === 'Send to others') {
            switch (key) {
                case 'save':
                    save_template('save');
                    break;
                case 'template':
                    save_template('template');
                    break;
                default:
                    save_template('proceed');
                    break;
            }
        } else {
            if (recipient?.find(s => s.type !== 2) || signType === 'Sign myself') {
                toast.error('Please add at least one field before save.');
            } else {
                if (key === 'proceed') {
                    toast.warning('Atleast one signer recipient with field is needed before sending.');
                } else {
                    toast.warning('Atleast one signer recipient with field is needed before save.');
                }
                setShowModal(true);
            }
        }
    };

    const handleDocumentName = (value) => {
        setDocName(value);
    };

    const handleSignerType = (value) => {
        let signerType = value ? 'Sign myself' : 'Send to others';
        setSignType(signerType);
    };

    const handleRemoveBackground = (color) => {
        if (color === selectColor) {
            let bg_color = recipient[0]['background'];
            setSelectColor(bg_color);
            setSelectRecipients(0);
        }
    };

    const signerType = (val) => {
        setSignType(val);
    };

    const handleReject = (value) => {
        let signId = window.location?.href?.split('&')[0]?.split('=')[1];
        dispatch(toggleLoader(true));
        setLoading(true);
        let sign_details = { sign_id: signId, org_id: sdk_org_id, reason: value };
        axios.post(process.env.REACT_APP_APPROVAL_REJECT, sign_details, { withCredentials: process.env.REACT_APP_CREDENTIALS })
            .then((res) => {
                if (res.data.success) navPath('/send-sign');
                dispatch(toggleLoader(false));
                setLoading(false);
            }).catch((err) => {
                dispatch(toggleLoader(false));
                toast.error(err.message);
                setLoading(false);
            })
    };


    const handleApprove = (value) => {
        let signId = window.location?.href?.split('&')[0]?.split('=')[1];
        dispatch(toggleLoader(true));
        setLoading(true);
        let sign_details = { sign_id: signId, org_id: sdk_org_id, reason: value };
        axios.post(process.env.REACT_APP_APPROVE, sign_details, { withCredentials: process.env.REACT_APP_CREDENTIALS })
            .then((res) => {
                if (res.data.success) navPath('/send-sign');
                dispatch(toggleLoader(false));
                setLoading(false);
            }).catch((err) => {
                dispatch(toggleLoader(false));
                toast.error(err.message);
                setLoading(false);
            })
    };

    const handleEditFields = () => {
        let signId = window.location?.href?.split('&')[0]?.split('=')[1];
        dispatch(toggleLoader(true));
        setLoading(true);
        let options = { sign_id: signId, org_id: sdk_org_id };
        axios.put(process.env.REACT_APP_APPROVALREQUEST, options, { withCredentials: process.env.REACT_APP_CREDENTIALS })
            .then((res) => {
                setApprovals(false);
                dispatch(toggleLoader(false));
                setLoading(false);
                setOpen(false);
                navigate(`?id=${signId_details?.id}&action=draft`);
                let approved = signId_details?.approvers_info?.find((item) => item.status === 1);
                let sign_id_details = { ...signId_details };
                sign_id_details['approvers_info'] = [];
                if (approved?.status === 1) dispatch(signId_action(sign_id_details));
            }).catch((err) => {
                dispatch(toggleLoader(false));
                toast.error(err.message);
                setLoading(false);
                setOpen(false);
            })
    };

    return (
        <div className="pdf-viewer">
            {fileLoader ?
                <div className="pdf-header">
                    <div className="top-bar">
                        <div className="pdf-top-bar-right-side">
                            <div className="document-back" onClick={() => go_to_mydocument()}>
                                <img
                                    src={process.env.REACT_APP_IMAGE_URL + 'DocumentSvg/document_back.png'}
                                    className='document-back-img'
                                    alt='add-recipient'
                                />
                            </div>
                            <div className="title-sec">
                                <div className="doc-title document_name">
                                    <input type="text" disabled={draft || (approvals && !pathName)} value={docName} onChange={(e) => handleDocumentName(e.target.value)} />
                                </div>
                                <div className="document_modified_time">
                                    <div>Modified on <span>{date}</span></div>
                                </div>
                            </div>
                        </div>
                        {/* {(admin || ((show_integeration?.user_permission[3].create === 1) && (show_integeration?.user_permission[4].create === 1))) && */}
                        <div className="pdf-button">
                            {recipient?.length === 0 || signType === 'Sign myself' ?
                                <Button disabled={!closeModal} onClick={() => (!draftType && pathName !== 'edit-template') && setShowModal(true)} className="add-recipient" variant="outlined">
                                    <img
                                        src={process.env.REACT_APP_IMAGE_URL + 'BasicFields/add-recipient-icon.svg'}
                                        className='recipient-img'
                                        alt='add-recipient'
                                    />
                                    <Box className="add_recipients">Add Recipient</Box>
                                </Button>
                                :
                                <AvatarGroup max={15} sx={{ bgcolor: '#FFFFFF !important', zIndex: 1 }} onClick={(e) => e.target.alt === 'recipient-add' && setShowModal(true)}>
                                    {recipient?.map((item, i) => {
                                        return (
                                            item?.type === 1 && <Tooltip title={item.name} key={i}>
                                                <Avatar onClick={() => [setSelectRecipients(i), setSelectColor(item?.background)]} key={i} alt={`avatar${item.id - 1}`}
                                                    sx={{
                                                        bgcolor: selectRecipients === i ? item?.backgroundColor : item?.background,
                                                        width: '40px', height: '40px', border: `1px solid ${item?.background} !important`,
                                                        fontFamily: 'var(--primary-font-family)',
                                                        color: selectRecipients === i ? '#FFFFFF' : '#616161'
                                                    }}>{item?.name?.charAt(0)?.toUpperCase()}</Avatar>
                                            </Tooltip>
                                        )
                                    })}
                                    <Avatar alt="recipient-add" className="avatar" src={process.env.REACT_APP_IMAGE_URL + 'BasicFields/person-add.svg'} />
                                </AvatarGroup>}
                            {pathName !== 'edit-template' ? disable_send_btn ?
                                approvals && (draftType === 'action=approval' || draftType === 'action=edit') ?
                                    <>
                                        <Button variant="contained" sx={{ marginRight: '5px', cursor: loading && 'not-allowed' }} className="btn-save" onClick={() => [!loading && setOpen(true), setReasonType('edit')]}>
                                            Edit
                                            <EditIcon sx={{ width: 15, height: 15, marginLeft: '5px' }} />
                                        </Button>
                                        {draftType === 'action=approval' && approver_user?.status !== 1 &&
                                            <>
                                                <Button variant="outlined" color="error" sx={{ marginRight: '5px', cursor: loading && 'not-allowed' }} className="btn-save" onClick={() => [!loading && setOpen(true), setReasonType('reject')]}>
                                                    Reject
                                                    <ThumbDownAltIcon sx={{ width: 15, height: 15, marginLeft: '5px' }} />
                                                </Button>
                                                <Button variant="contained" sx={{ marginRight: '5px', cursor: loading && 'not-allowed' }} className="btn-save" onClick={() => [!loading && setOpen(true), setReasonType('approve')]}>
                                                    Approve
                                                    <ApprovalIcon sx={{ width: 15, height: 15, marginLeft: '5px' }} />
                                                </Button>
                                            </>}
                                    </>
                                    :
                                    <>
                                        {approvals && <Button variant="contained" sx={{ marginRight: '5px', cursor: loading && 'not-allowed' }} className="btn-save" onClick={() => [!loading && setOpen(true), setReasonType('edit')]}>
                                            Edit
                                            <EditIcon sx={{ width: 15, height: 15, marginLeft: '5px' }} />
                                        </Button>}

                                        <Button variant="contained" sx={{ marginRight: '5px', cursor: loading && 'not-allowed' }} className="btn-save" onClick={() => !loading && handleErrorMsg()}>
                                            {user_id?.length && draftType !== 'action=approved' && signType === 'Send to others' ? 'Send for Approval' : "Send"}
                                            <img
                                                src={process.env.REACT_APP_IMAGE_URL + 'DocumentSvg/send-fill.svg'}
                                                className='send-doc-img'
                                                alt='send'
                                            />
                                        </Button>
                                    </>
                                : null
                                :
                                <>
                                    {(admin || (show_integeration?.user_permission[2].update === 1)) &&
                                        <Button variant="contained" className="btn-save" sx={{ marginLeft: '5px', cursor: loading && 'not-allowed' }} onClick={() => { !loading && handleSave('save') }}>Save</Button>}
                                    {(admin || disable_send_btn) &&
                                        <Button variant="contained" className="btn-save-proceed" onClick={() => { !loading && handleSave('proceed') }} sx={{ cursor: loading && 'not-allowed' }}>
                                            Save and Proceed
                                            <img
                                                src={process.env.REACT_APP_IMAGE_URL + 'DocumentSvg/send-fill.svg'}
                                                className='send-doc-img'
                                                alt='send'
                                            />
                                        </Button>}
                                </>}


                            <div className="select-items">
                                <div className='viewer_condition_icon_button'>
                                    <img
                                        src={process.env.REACT_APP_IMAGE_URL + 'DocumentSvg/select.svg'}
                                        className='recipient-img'
                                        alt='add-recipient'
                                    />
                                    <Select
                                        MenuProps={{ classes: { paper: 'select_condition_more_options' } }}
                                        className='select-container'
                                        value=''
                                    >
                                        <div className="select-header">settings</div>
                                        {(admin || (show_integeration?.user_permission[2]?.create === 1 || newTemplate)) &&
                                            <MenuItem className="select-body" disabled={pathName || approvals ? true : signId_details?.crm_param === 'crm_draft' || (window?.location?.href.includes('crmparam') && pulseId && pulseId !== 'null') || window?.location?.href.includes('crmparam') ? true : false} onClick={(e) => handleSave('template')}>
                                                <img
                                                    src={process.env.REACT_APP_IMAGE_URL + 'DocumentSvg/template.svg'}
                                                    className='select-img'
                                                    alt='add-recipient'
                                                />
                                                <div className="save-template">Save as Template</div>
                                            </MenuItem>}
                                        {/* <MenuItem className="select-body">
                                            <img
                                                src={process.env.REACT_APP_IMAGE_URL + 'DocumentSvg/close.svg'}
                                                className='select-img'
                                                alt='add-recipient'
                                            />
                                            <span>Save and Close</span>
                                        </MenuItem>
                                        <MenuItem className="select-body">
                                            <img
                                                src={process.env.REACT_APP_IMAGE_URL + 'DocumentSvg/trash.svg'}
                                                className='select-img'
                                                alt='add-recipient'
                                            />
                                            <span>Discard</span>
                                        </MenuItem> */}
                                        <div className="select-header">Recipients</div>
                                        {/* {(admin || show_integeration?.user_permission[2].update === 1) && */}
                                        <MenuItem className="select-body" onClick={() => recipient?.length > 0 || signType === 'Sign myself' ? draftType === 'action=draft' ? setShowModal(false) : setShowModal(true) : toast.error('Please add recipients')}>
                                            <img
                                                src={process.env.REACT_APP_IMAGE_URL + 'DocumentSvg/edit-recipients.svg'}
                                                className='select-img'
                                                alt='add-recipient'
                                            />
                                            <div className="save-template">Edit Recipients</div>
                                        </MenuItem>
                                        {/* } */}
                                    </Select>
                                </div>
                            </div>
                        </div>
                        {/* } */}
                    </div>
                </div> : <Skeleton sx={{ bgcolor: 'rgba(189,197,209,.2)', margin: '10px' }} animation="wave" variant="rounded" width={'calc(100% - 20px)'} height={'calc(100vh - 20px)'} />}
            <div className="pdf-header">
                <PDFData
                    controls_={(item) => setControls(item)}
                    signType={signType}
                    select_Recipients={selectRecipients}
                    selectColor={selectColor}
                    removebackground={(color) => handleRemoveBackground(color)}
                    recipient={recipient}
                    handleRecipientHandler={(item) => handleRecipient(item)}
                    show_Modal={showModal}
                    signerType={(val) => signerType(val)}
                    signerCheckBox_={(e) => handleSignerType(e)}
                    closeModal={() => setShowModal(false)}
                    pageNumber={(val) => setSelectedPage(val)}
                    selectedPage={selectedPage}
                    fileLoader={(val) => setFileLoader(val)}
                    pageInfo_={(page) => setPageInfo(page)}
                    signOrder={(val) => setSignOrder(val)}
                    getPasscode={(value) => setPassCodeDetails(value)}
                    getUserId={(id) => setUser_id(id)}
                    approvals_={setApprovals}
                    approvals_list={approvals}
                />
            </div>
            <LimitModal limit={limit} handleModalClose={() => setLimit(false)} />
            <ApprovarModal open={open} handleApprove={(value) => handleApprove(value)} handleReject={(value) => handleReject(value)} type={reasonType} closeModal={() => setOpen(false)} handleEditFields={handleEditFields} loading={loading} />
        </div>
    )
};

export default PDFViewer;