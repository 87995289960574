import React, { useEffect, useState } from 'react';
import styles from './SendSign.module.css';
import CustomTable from '../../component/customTable/CustomTable';
import AppHeader from '../../component/appHeader/AppHeader';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, debounce, IconButton, Modal } from '@mui/material';
import SignDetails from '../../component/SignDetails';
import CloseIcon from '@mui/icons-material/Close';
import { toggleLoader } from '../../store/loader/loaderReducer';
import { Navigation } from '../../utils/handleNavigation';
import { toast } from 'react-toastify';
import { signId_action } from '../../store/signIDDetails/signId_action';
import { getTemplatePreview } from '../../Api/TemplatePreviewApi';
import { useLocation, useNavigate } from 'react-router-dom';
import { getSendSign } from '../../Api/SendSignApi';
import { LimitModal } from '../../component/LimitModal';
import Skeleton from '@mui/material/Skeleton';

const tHead = ['Template Name', 'Status', 'Source', 'Receiver', 'Created Time'];
const keys = ['Template_Name', 'Status', 'source_name', 'Email_Id', 'Created_Time'];

const signStatus = [
  { status: 'Declined', color: '#F72222' },
  { status: 'Sent', color: ' #2C47CB' },
  { status: 'Progress', color: '#AB6BFF' },
  { status: 'Completed', color: '#23AB94' },
  { status: 'Declined', color: '#F72222' },
  { status: 'Expired', color: '#F38435' },
  { status: 'Draft', color: ' #F5CA99' },
  { status: 'Viewed', color: ' #AB6BFF' },
  { status: 'Recall', color: ' #56BAE1' },
  { status: 'Pending Approval', color: '#F72222' },
  { status: 'Approved', color: '#23AB94' },
  { status: 'Rejected', color: '#F72222' }
];

const SendSign = () => {
  const dispatch = useDispatch();
  const navPath = Navigation();
  const navigate = useNavigate();
  const location = useLocation();
  const sdkPath = location.pathname.split('/')[1];

  const document = useSelector((state) => state?.document?.value);
  const crm_param = useSelector((state) => state.crm_param.value);
  const show_integeration = useSelector((state) => state.welcomeImgDetail.value);
  const admin = show_integeration?.user_information?.userRole.includes('Super Admin', 'Admin');

  const [signDetails, setSignDetails] = useState([]);
  const [checkedId, setCheckedId] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [toggleSignJourney, setToggleSignJourney] = useState(false);
  const [signJourneyDetails, setSignJourneyDetails] = useState([]);
  const [pageShow, setPageshow] = useState(false)
  const [show, setShow] = useState(false);
  const [pageIndex, setPageIndex] = useState(false);
  const [open, setOpen] = useState(true);
  const [limit, setLimit] = useState(false);
  const [page_idx, setPage_idx] = useState(0);
  const [page_size, setPage_size] = useState(10);

  useEffect(() => {
    fetchSignForOthers();
  }, []);

  useEffect(() => {
    let reload = window.performance.getEntriesByType('navigation').map((nav) => nav.type);
    if (reload[0] === 'reload' && sdkPath === 'sdk') {
      navigate(`?crmparam=${crm_param}`);
    };
  }, [sdkPath]);

  const convertKeysToLowerCase = (obj) => {
    const newObj = {};
    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const lowerCaseKey = key.toLowerCase();
        newObj[lowerCaseKey] = obj[key];
      }
    }
    return newObj;
  };

  const handleClick = (id) => {
    if (signStatus[id?.statustype].status === 'Draft') {
      dispatch(toggleLoader(true));
      dispatch(signId_action(null));
      getTemplatePreview(id, dispatch, (res) => {
        let data = res.data;
        const newObj = convertKeysToLowerCase(id);
        dispatch(signId_action(newObj));
        if (window.location.ancestorOrigins[0]) {
          if (window.location.ancestorOrigins[0].includes("monday.com") || window.location.ancestorOrigins[0].includes("lightning.force.com") || window.location.ancestorOrigins[0].includes("hubspot.com")) {
            navigate('/sdkhome', { state: { set_refresh: true } });
            window.open(`${process.env.REACT_APP_URL}/#/c${document?.OrgId}/send-sign/${data.template_information.Id}?id=${id?.Id}&action=draft&crmparam=${crm_param}`, '_blank');
            dispatch(toggleLoader(false));
          }
        } else {
          navPath(`/send-sign/${data.template_information.Id}?id=${id?.Id}&action=draft`, 'document_reload');
        }
      }, (err) => {
        toast.error(err.message);
        dispatch(toggleLoader(false));
      })
    } else {
      if (window.location.ancestorOrigins[0]) {
        if (window.location.ancestorOrigins[0].includes("monday.com") || window.location.ancestorOrigins[0].includes("lightning.force.com") || window.location.ancestorOrigins[0].includes("hubspot.com") || window.location.ancestorOrigins[0].includes("pipedrive.com")) {
          navigate(`/sdk/my-document/${id?.Id}`);
        }
      } else {
        navPath(`/send-sign-details/${id?.Id}`);
      }
      dispatch(toggleLoader(true));
    }
  };


  const checkAncestorOrigins = () => {
    const ancestorOrigins = window?.location?.ancestorOrigins;
    if (ancestorOrigins && ancestorOrigins.length > 0) return true;
    else return false;
  };

  const fetchSignForOthers = (idx = 0, size = 10, val, key) => {
    dispatch(toggleLoader(true));
    let params = {
      org_id: document?.OrgId,
      limit: size ?? 10,
      ...(idx > 0 ? { cursor: key ? idx : idx + 1 } : { cursor: 1 }),
      ...(val?.length > 0 ? { search: val } : {}),
    };

    const orgin = checkAncestorOrigins();
    if (orgin) {
      if (window.location.ancestorOrigins[0].includes("monday.com") || window.location.ancestorOrigins[0].includes("lightning.force.com") || window.location.ancestorOrigins[0].includes("hubspot.com")) {
        params.advsearch = crm_param;
      }
    }

    getSendSign(params, (res) => {
      if (idx === 0) {
        setTotalRecord(res.data.total_record);
      }

      const data = res.data.sign_for_others_list.map((o, i) => {
        const {
          Status,
          Modified_Time,
          Sign_Journey,
          Created_Time,
          Id,
          ...restData
        } = o;

        const statusEl = (
          <Box style={{ display: 'flex', gap: '10px', alignItems: 'center' }} key={i}>
            <Box
              className={styles.custom_dot}
              style={{ backgroundColor: signStatus[Status].color }}
            ></Box>
            {signStatus[Status].status}
          </Box>
        );

        return {
          ...restData,
          Created_Time: Created_Time,
          Status: statusEl,
          Id: Id,
          statustype: Status,
        };
      });
      setShow(false);
      setSignDetails(data);
      setPageshow(true);
      dispatch(toggleLoader(false));
    }, (err) => {
      dispatch(toggleLoader(false));
      if (err?.response?.data?.error?.status === 600) {
        setLimit(true);
      } else {
        toast.error(err.message);
      }
    })
  };

  const debouncedSendRequest = debounce(fetchSignForOthers, 800);

  const handleSearch = (idx = 0, size = 10, val) => {
    const encode = encodeURIComponent(val);
    debouncedSendRequest(idx = 0, size = 10, encode);
  };

  const deleteSignForOthers = (_id, resetId = () => { }, pageSize, cursor) => {
    dispatch(toggleLoader(true));
    const id = _id ? [_id] : checkedId;

    let data = null;

    if (process.env.NODE_ENV === 'development') {
      data = JSON.stringify({
        org_id: document?.OrgId,
        ids: id,
        limit: pageSize,
        cursor: cursor,
      });
    } else {
      if (window.location.ancestorOrigins[0]) {
        if (window.location.ancestorOrigins[0].includes("monday.com") || window.location.ancestorOrigins[0].includes("lightning.force.com") || window.location.ancestorOrigins[0].includes("hubspot.com") || window.location.ancestorOrigins[0].includes("pipedrive.com")) {
          data = JSON.stringify({
            org_id: document?.OrgId,
            ids: id,
            limit: pageSize,
            cursor: cursor,
            advsearch: crm_param
          });
        }
      }

      if (sdkPath !== 'sdk') {
        data = JSON.stringify({
          org_id: document?.OrgId,
          ids: id,
          limit: pageSize,
          cursor: cursor,
        });
      }
    }

    const headers = {
      'Content-Type': 'application/json',
    };

    axios.delete(process.env.REACT_APP_SIGNFOROTHERS, { headers: headers, data: data, withCredentials: process.env.REACT_APP_CREDENTIALS })
      .then((res) => {
        resetId();
        const data = res.data.sign_for_others_list.map((o, i) => {
          const {
            Status,
            Modified_Time,
            Sign_Journey,
            Created_Time,
            Id,
            ...restData
          } = o;

          const statusEl = (
            <Box style={{ display: 'flex', gap: '10px', alignItems: 'center' }} key={i}>
              <Box
                className={styles.custom_dot}
                style={{ backgroundColor: signStatus[Status].color }}
              ></Box>
              {signStatus[Status].status}
            </Box>
          );

          return {
            ...restData,
            Created_Time: Created_Time,
            Status: statusEl,
            Id: Id,
            statustype: Status,
          };
        });

        if (data?.length) {
          setSignDetails(data);
        } else {
          fetchSignForOthers(cursor - 1, pageSize, null, 'delete');
          setPageIndex(true);
        }
        setTotalRecord(totalRecord - id?.length);
        setPageshow(true);
        dispatch(toggleLoader(false));
        setShow(false);
        toast.success('Success');
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(toggleLoader(false))

      })
      .finally(() => dispatch(toggleLoader(false)));
  };

  const handleNavigate = () => {
    if (sdkPath === 'sdk') {
      navigate(`/sdk/upload-document?crmparam=${crm_param}`);
    } else {
      navPath('/upload');
    }
  };

  return (
    <>
      {pageShow ? (
        <Box className={sdkPath === 'sdk' && (show_integeration?.show_banner_info?.length && JSON.parse(show_integeration?.show_banner_info)[0]?.banner_details?.MONDAY_WELCOME) && open ? styles.crm_main_wrap : styles.main_wrap}>
          <>
            <AppHeader
              btnText='Upload'
              crm_upload={sdkPath === 'sdk' ? 'monday_crm' : ''}
              moduleText='Send Sign Requests'
              body={signDetails}
              tool_tip_open={() => setOpen(false)}
              onBtnClick={() => handleNavigate()}
              rolePermissions={show_integeration?.user_permission[1]}
              checkAdmin={admin}
            />
            <CustomTable
              getPageIndexSize={({ pageIdx, pageSize }) => {
                setPage_idx(pageIdx);
                setPage_size(pageSize);
                fetchSignForOthers(pageIdx, pageSize);
              }}
              onSearch={({ pageIdx, pageSize, value }) =>
                handleSearch(0, pageSize, value)
              }
              page_idx={page_idx}
              page_size={page_size}
              totalRecordCount={totalRecord}
              tool_tip={open}
              pageDeleteIndex={pageIndex}
              pageSetIndex={(val) => setPageIndex(val)}
              searchName='Search Sign Requests'
              keys={keys}
              head={tHead}
              body={signDetails}
              onDelete={deleteSignForOthers}
              getCheckedId={setCheckedId}
              onRowClick={(val) => {
                (admin || (show_integeration?.user_permission[3]?.read === 1 || show_integeration?.user_permission[3]?.update === 1)) &&
                  handleClick(val);
              }}
              rolePermissions={show_integeration?.user_permission[3]}
              checkAdmin={admin}
            />
          </>

          <Modal
            open={toggleSignJourney}
            onClose={() => setToggleSignJourney(false)}
            closeAfterTransition
          >
            <Box className={styles.new_contact_wrap}>
              <IconButton
                className={styles.close}
                onClick={() => setToggleSignJourney(false)}
              >
                <CloseIcon sx={{ "&:hover": { color: "#ed4c78" } }} />
              </IconButton>
              <Box component='h2'>Sign Journey</Box>
              {signJourneyDetails.map((item, i) => {
                return (
                  <div key={i}>
                    <Box style={{ padding: '20px' }}>{item.Email_Id}</Box>
                    {item.Status === 0 ? (
                      <Box
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          padding: '20px',
                        }}
                      >
                        <Box style={{ display: 'flex', gap: '3px', alignItems: 'center' }}>
                          <Box
                            className={styles.custom_dot}
                            style={{ backgroundColor: '#78d476' }}
                          >
                          </Box>
                          completed
                        </Box>
                        <Box>{item.Time}</Box>
                      </Box>
                    ) : (
                      ''
                    )}
                  </div>
                );
              })}
            </Box>
          </Modal>
        </Box>) : <Skeleton sx={{ bgcolor: 'rgba(189,197,209,.2)', margin: '10px' }} animation="wave" variant="rounded" width={'calc(100% - 20px)'} height={'calc(100vh - 80px)'} />}
      <LimitModal limit={limit} handleModalClose={() => setLimit(false)} />
    </>
  );
};

export default SendSign;